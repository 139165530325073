import { Container, Grid, Paper } from "@mui/material";
import Chart from "../components/Chart";
import Copyright from "../components/Copyright";
import LatestSubscription from "../components/LatestSubscription";
import Event from "../components/Event";
import Invoices from "../components/Invoices";

export default function Dashboard() {
	return (
		<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
			<Grid container spacing={3}>
				{/* Chart */}
				<Grid item xs={12} md={8} lg={9}>
					<Paper
						sx={{
							p: 2,
							display: 'flex',
							flexDirection: 'column',
							height: 240,
						}}
					>
						<Chart />
					</Paper>
				</Grid>
				{/* Recent Deposits */}
				<Grid item xs={12} md={4} lg={3}>
					<Paper
						sx={{
							p: 2,
							display: 'flex',
							flexDirection: 'column',
							height: 240,
						}}
					>
						<LatestSubscription />
					</Paper>
				</Grid>
				{/* Recent Orders */}
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
						<Invoices />
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
						<Event />
					</Paper>
				</Grid>
			</Grid>
			<Copyright sx={{ pt: 4 }} />
		</Container>
	)
}