import { Button, CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import Copyright from "../components/Copyright";
import { store } from "../store/store";


export default function Upload() {
	const globalState = useContext(store);
	const { dispatch } = globalState;
	const [faq, setFaq] = React.useState("");
	const [abbrev, setAbbrev] = React.useState("");
	const [syn, setSyn] = React.useState("");
	const [obj, setObj] = React.useState({});
	const [loading, setLoading] = React.useState(null);

	const handlePost = async () => {
		console.log(obj)
		const res = await axios.post('https://product.chat/flask/upload_faq', obj)
		// console.log(res)

		
		dispatch({ type: "company", value: res.data.company_id })

		async function updateCompany() {
			try {
				const update = await axios.post(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/admin/company`, {
					company_id: res.data.company_id
				},
				{
					headers: {
						Authorization: `Bearer ${globalState.state.jwt}`
					}
				})
				console.log(update)

			} catch (e) {
				console.log(e.response)
			} 

		}
		updateCompany()

		setLoading(true)
		// console.log(res.data.company_id)
		const bananas = setInterval(async () => {
			const status = await axios.get(`https://product.chat/flask/check_upload_faq_status/${res.data.company_id}`)
			
			// console.log(status)
			if (status.data.inprocess === false) {
			
				clearInterval(bananas)
				setLoading(false)
			}
		}, 500)
	}


	const handleExcel = (e, type) => {

		if (e.target.files[0] === undefined) return null

		if (e.target.files[0].name.slice(-4) !== `xlsx` || e.target.files[0].name.slice(-3) !== `xls`) {

			var file = e.target.files[0];
			var filename = e.target.files[0].name
			var fileReader = new FileReader();
			var result

			fileReader.onload = async function (e) {

				if (e.target && e.target.result) {
					var data = new Uint8Array(e.target.result);

					import("xlsx").then(async (xlsx) => {
						// do something with xlsx
						var workbook = xlsx.read(data, { type: "array" });
						var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
						result = xlsx.utils.sheet_to_json(firstSheet);

						switch (type) {
							case 'faq': {
								setObj({ ...obj, faq: result })
								setFaq(filename)
								break;
							}

							case 'abbrev': {
								setObj({ ...obj, abbr: result })
								setAbbrev(filename)
								break;
							}

							case 'syn': {
								setObj({ ...obj, syn: result })
								setSyn(filename)
								break;
							}
							default:{
								break;
							}
						}
					})
				}
			};
			fileReader.readAsArrayBuffer(file);

		} else {
			alert(`Incorrect File Format`);
		}
	}

	React.useEffect(() => {

		async function checkCompany() {
			try {
				const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/admin/company`, {
				headers: {
					Authorization: `Bearer ${globalState.state.jwt}`
				}
			})
			if (res.data.company_id) {
				setObj({ ...obj, company_name: res.data.company_name, company_id: res.data.company_id, new_client:false })
			}
			else {
				setObj({ ...obj, company_name: res.data.company_name, new_client: true })
			}

			} catch(e){
				console.log(e.response)
			} 
		}
		checkCompany()

		console.log(globalState.state)
	
		
		// await axios.post
	}, [])

	return (
		<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
			
			<Grid container spacing={3}>
				{!loading ?
				<>
				<Grid item xs={12} md={12} lg={12}>
					<Paper>
						<label htmlFor="importFile">
							<Button component="span">
								Import Questions File
							</Button>
							{
								faq && <Typography sx={{ pl: 2 }}>{faq}</Typography>
							}
							<input style={{ display: "none" }} type="file" id="importFile" onChange={(event) => handleExcel(event, 'faq')} accept=".xlsx, .xls" />
						</label>
					</Paper>
				</Grid>
				<Grid item xs={12} md={12} lg={12}>
					<Paper>
						<label htmlFor="importAbb">
							<Button component="span">
								Import Abbreviations File
							</Button>
							{
								abbrev && <Typography sx={{ pl: 2 }}>{abbrev}</Typography>
							}
							<input style={{ display: "none" }} type="file" id="importAbb" onChange={(event) => handleExcel(event, 'abbrev')} accept=".xlsx, .xls" />
						</label>
					</Paper>
				</Grid>
				<Grid item xs={12} md={12} lg={12}>
					<Paper>
						<label htmlFor="importSyn">
							<Button component="span">
								Import Synonyms File
							</Button>
							{
								syn && <Typography sx={{ pl: 2 }}>{syn}</Typography>
							}
							<input style={{ display: "none" }} type="file" id="importSyn" onChange={(event) => handleExcel(event, 'syn')} accept=".xlsx, .xls" />
						</label>
					</Paper>
				</Grid>

				
					{faq &&
					<Grid item xs={12} md={12} lg={12}>
						<Paper>
							<Button onClick={handlePost}>
								Upload
							</Button>
						</Paper>
					</Grid>}
				</>
				:
					<Grid item xs={12} md={12} lg={12}>
					<Paper sx={{display:"flex", height:600, width:"100%"}}>
							<Box sx={{ display: 'flex', m: "auto" }}>
								<CircularProgress />
							</Box>
					</Paper>
				</Grid>
			

			}

			</Grid>
		
			<Copyright sx={{ pt: 4 }} />

		</Container>
	)
}