import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import axios from 'axios';
import { store } from '../store/store';
import capitalizeFirstWord from "../utils/capitalizeFirstWord";

export default function Event() {
	const globalState = React.useContext(store);
	const { dispatch } = globalState;

	const [data, setData] = React.useState([]);

	const handleGet = async () => {
		// console.log('triggered get invoice')
		const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/dashboard/event/`,
			{
				headers: {
					Authorization: `Bearer ${globalState.state.jwt}`,
				},
			}
		);

		// console.log(JSON.stringify(res.data.faq, null, 4))
		// console.log(res.data)

		if (res.data) setData(res.data)
	}

	React.useEffect(() => {
		handleGet()
	}, [])
	return (
		<React.Fragment>
			<Title>Event</Title>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Time</TableCell>
						<TableCell>Event</TableCell>
						<TableCell>Message</TableCell>
						{/* <TableCell>Status</TableCell>
						<TableCell align="right">Amount</TableCell> */}
					</TableRow>
				</TableHead>
				<TableBody>
					{data ? data.map((row) => (
						<TableRow key={row._id}>
							<TableCell>{new Date(row.createdAt).toLocaleString('en-HK')}</TableCell>
							<TableCell>{row.event_name}</TableCell>
							<TableCell>{row.message}</TableCell>
						</TableRow>))
						: <></>
					}
				</TableBody>
			</Table>
			{/* <Link color="primary" onClick={() => { dispatch({ type: "page", value: "profile" }) }} sx={{ mt: 3 }}>
				Download or View more Invoices
			</Link> */}
		</React.Fragment>
	);
}