import {Container, Grid, Paper } from "@mui/material";
import Copyright from "../components/Copyright";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { store } from "../store/store";
import APICard from "../components/APICard";
import GuideCard from "../components/GuideCard";




export default function Guide() {
	const globalState = useContext(store);
	// const { dispatch } = globalState;
	const [data, setData] = useState([]);
	

	const handleGet = async () => {
		console.log('triggered get')
		const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/guides/`,
			{
				headers: {
					Authorization: `Bearer ${globalState.state.jwt}`,
				},
			}
		);
	
		// console.log(JSON.stringify(res.data.faq, null, 4))
		console.log(res.data)

		setData(res.data)
		// array of
		// {
		// 	"_id": "61de91db18c0aa4936004a76",
		// 		"user_id": "61de91af18c0aa4936004a71",
		// 			"usage": 0,
		// 				"product": "invoice",
		// 					"apiKey": "cacad9deca34554e0dab9b2bef39c40e",
		// 						"tier": "1",
		// 							"createdAt": "2022-01-12T08:31:23.583Z",
		// 								"updatedAt": "2022-01-12T08:31:23.583Z",
		// 									"__v": 0
		// }
	}

	useEffect(() => {
		// handleGet()
	}, [])

	return (
		<Container maxWidth="false" sx={{ mt: 4, mb: 4, maxWidth: "1400px" }}>

			<Paper sx={{ height: 600, width: '100%', p:0 }}>
				<Grid container spacing={2} sx={{p:0, m:0}}> 
					{/* {data && data.map((api)=> {
					return (
					<Grid key={api._id} item xs={12} md={6} lg={4} sx={{p:0, m:0}}>
						<APICard one={api.product} two={api.tier} three={api.createdAt} four={api.stripe_usage} hidden={api.apiKey}/>	
					</Grid>
					)
					})} */}
					<Grid key={"test"} item xs={12} md={6} lg={6}>
						<GuideCard title="test" desc="test" media="test.png" url="test" />
					</Grid>
				</Grid>
			</Paper>
			
			
			<Copyright sx={{ pt: 4 }} />
		</Container>

	)
}