
import { useContext, useEffect } from 'react';
import { store } from '../store/store';
import Mainframe from '../layouts/Mainframe';
import Login from '../layouts/Login';
import Register from '../layouts/Register';
import {BrowserRouter, Route} from 'react-router-dom';
import Company from '../layouts/Company';
import axios from 'axios';
import Alert from '../components/Alert';

function Router() {

	const globalState = useContext(store);
	const { dispatch } = globalState;

	useEffect(()=> {
		const subdomain = window.location.hostname.split(".")[0]

		console.log(subdomain)
	// }, [JSON.stringify(globalState.state)])
		async function verify() {
			try {
				const response = await axios.post('https://product.chat/api2/auth/verify', {
					verify: true
				}, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				})

				if (response.data) {
					dispatch({type:'jwt', value: localStorage.getItem('token')})
					dispatch({ type: "companyName", value: localStorage.getItem('cn') })
				}
				return response.data
			} catch (e) {
				// console.log(e.response)
				localStorage.removeItem('token'); dispatch({ type: "logout", value: true })
				console.log(e)
			}
			
		}
		if (localStorage.getItem('token')) verify()

		const heartbeat = setInterval(() =>{  
			verify()
		},1800000)

		return ()=> {clearInterval(heartbeat)}
	}, [])

	return (
		<>
		{globalState.state.jwt ?
			globalState.state.companyName  ? 
			<Mainframe />
			:
			<Company />
			:
			// globalState.state.page === "subscribe" ?
			// 	<Subscribe />
			// 	:
			// globalState.state.page === "register" ?
			// 	<Register />
			// 	:
				<Login />}
		<Alert />
		</>
	);
}

export default Router;