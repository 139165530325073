import {useContext, useState, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import Copyright from '../components/Copyright';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { store } from '../store/store';
import axios from 'axios';

const theme = createTheme();

export default function Login() {


	const globalState = useContext(store);
	const { dispatch } = globalState;
	const [error, setError] = useState(false);
	const [confirm, setConfirm] = useState(false)
	const [forget, setForget] = useState(false)
	const [reset, setReset] = useState('')
	const [counter, setCounter] = useState(60);
	const [countdownOngoing, setCountdownOngoing] = useState(false);


	useEffect(()=> {
		console.log(window.location.search)
		if(window.location.search) {
			const search = new URLSearchParams(window.location.search)
			console.log(search.get("rc"))	
			async function check() {
				const res = await axios.post(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/auth/check_rc`,
					{ rc: search.get("rc")})
				console.log(res)
				if(res.status === 200)
				setReset(search.get("rc"))
			}
			check()
		}
	},[])

	// const handleRegister = () => {
	// 	// dispatch({type:"page", value:"subscribe"})
	// 	dispatch({ type: "page", value: "register" })

	// }

	const handleResend = async (event) => {
		const email = localStorage.getItem('email')

		const res = await axios.post(
			`${process.env.REACT_APP_ENV_ENDPOINT}/api2/auth/resend`, {
			email
		}
		)

		console.log(res.data)
		setCountdownOngoing(true)


	}

	useEffect(() => {
		if (countdownOngoing) {
			if (counter === 0) {
				setCountdownOngoing(false)
				return
			}

			setTimeout(() => {
				setCounter(counter - 1)
			}, 1000)
		}

	}, [counter, countdownOngoing])


	const handleConfirm = async (event) => {
		event.preventDefault();

		const data = new FormData(event.currentTarget);

		const email = localStorage.getItem('email')

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_ENV_ENDPOINT}/api2/auth/confirm`,
				{
					code: data.get(`confirm`),
					email
				}
			)

			if (res.status === 200) {
				localStorage.setItem('token', res.data.token)
				dispatch({ type: "companyName", value: res.data.company_name })
				dispatch({ type: "jwt", value: res.data.token })
			}
		} catch (e) {
			console.log(e.message)
			setError(true)

			setTimeout(() => {
				setError(false);
			}, 3000)
		}
	}

	const handleForget = () => {
		setForget(true)
	}

	const handleReset = async (event) => {
		event.preventDefault();

		const data = new FormData(event.currentTarget);

		const res = await axios.post(
			`${process.env.REACT_APP_ENV_ENDPOINT}/api2/auth/reset`,
			{
				email: data.get(`email`),
				src: "admin"
			}
		)

		try {
			if (res.status === 200) {
				setCountdownOngoing(true)
				setForget(false)
			}
		} catch (e) {
			console.log(e.message)
			setError(true)

			setTimeout(() => {
				setError(false);
			}, 3000)
		}
		
	}

	const handleSet = async (event) => {
		event.preventDefault();

		const data = new FormData(event.currentTarget);

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_ENV_ENDPOINT}/api2/auth/set_new`,
				{
					code:reset,
					password: data.get(`password`)
				}
			)

			if (res.status === 200) {
				setReset(false)
			}
		} catch (e) {
			console.log(e.message)
			setError(true)

			setTimeout(() => {
				setError(false);
			}, 3000)
		}
	}


	const handleSubmit = async (event) => {
		event.preventDefault();

		const data = new FormData(event.currentTarget);
		// eslint-disable-next-line no-console

		// for (var key of data.keys()) {
		// 	console.log(key);
		// }

		try {
			const res = await axios.post(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/auth/login`, {
				email: data.get(`email`),
				password: data.get(`password`),
			})
			console.log(res)
			// try {
			const sub = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/pay/get_tier`, {
				headers: {
					Authorization: `Bearer ${res.data.token}`
				}
			})
			console.log(sub.data.tier)

			if (sub.data.tier === 1) throw new Error("Sorry, your current tier does not support Admin Panel usage.")

			if (res.status === 209) {
				localStorage.setItem('email', res.data.email)
				setConfirm(true)
			}
			if (res.status === 210) {
				localStorage.setItem('email', res.data.email)
				setReset(true)
			}
			else if (res.status === 200) {
				localStorage.setItem('token', res.data.token)
				localStorage.setItem('cn', res.data.company_name)
				dispatch({ type: "companyName", value: res.data.company_name })
				dispatch({ type: "jwt", value: res.data.token })

			}

		} catch(e) {
			setError(true)

			setTimeout(() => {
				setError(false);
			}, 3000)
			console.log(e.message)
		}


		// dispatch({type:"color", value:"white"})
		// const data = new FormData(event.currentTarget);
		// // eslint-disable-next-line no-console
		// console.log({
		// 	email: data.get('email'),
		// 	password: data.get('password'),
		// });
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />	{forget? 
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography variant="body2">
						Hi, if you've forgotten the password, we can send you a reset email.
					</Typography>
							<Box component="form" onSubmit={handleReset} noValidate sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email"
									name="email"
									autoComplete="email"
									autoFocus
								/>
								<Button
									type="submit"
									disabled={countdownOngoing}
									fullWidth
									variant="contained"
									sx={{ mt: 3, mb: 2 }}
									
								>
								{countdownOngoing ? `Send Reset Email (${counter})` : `Send Reset Email`}
								</Button>	
								{error &&
									<Typography sx={{color:"red"}} variant="body2">
										Unable to send reset email, please contact support.
									</Typography>}
								<Grid container>
									<Grid item>
										<Button onClick={()=>{setForget(false)}}
											variant="body2">
											I've remembered my password.
										</Button>
									</Grid>
								</Grid>
							</Box>
							
				</Box>
				: 
				reset? 
				<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography variant="body2">
					Please enter a new password for your account.
				</Typography>
						<Box component="form" onSubmit={handleSet} noValidate sx={{ mt: 1 }}>
						<TextField
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="new-password"
								/>		
								<TextField
									required
									fullWidth
									// name="password"
									label="Repeat Password"
									type="password"
									// autoComplete="new-password"
								/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Update Password
							</Button>	
							{error &&
								<Typography sx={{color:"red"}} variant="body2">
									Unable to set new login details, please contact support.
								</Typography>}
						</Box>
						
			</Box>
			:
				confirm ?
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography variant="body2">
						Hello, you're logging in for the first time, please check your email for a confirmation code.
					</Typography>
							<Box component="form" onSubmit={handleConfirm} noValidate sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="confirm"
									label="Confirm Code"
									name="confirm"
									autoComplete="confirm"
									autoFocus
								/>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{ mt: 3, mb: 2 }}
								>
									Confirm Email
								</Button>	
								{error &&
									<Typography sx={{color:"red"}} variant="body2">
										Incorrect Code. Please try again or resend the code.
									</Typography>}
								<Grid container>
									<Grid item>
										<Button onClick={handleResend}
											disabled={countdownOngoing}
											// href="#" 
											variant="body2">
											{countdownOngoing? `Resend (${counter})`: `Resend`}
										</Button>
									</Grid>
								</Grid>
							</Box>
							
				</Box>
				:
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						{/* <FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label="Remember me"
						/> */}
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign In
						</Button>
						{error &&
							<Typography sx={{ color: "red" }} variant="body2">
								Account not found, invalid credentials or your tier of subscription does not not support this feature. Please try again.
							</Typography>}
						<Grid container justifyContent="flex-start">
							<Grid item>
								<Button onClick={handleForget}>
									Forgot Your Password?
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Box>
				}
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</ThemeProvider>
	);
}