import { Button, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Copyright from "../components/Copyright";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { store } from "../store/store";
import UpdateProfileDialog from "../components/UpdateProfileDialog";
import Title from "../components/Title";
import capitalizeFirstWord from "../utils/capitalizeFirstWord";

export default function Profile() {
	const globalState = useContext(store);
	const { dispatch } = globalState;
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [personal, setPersonal] = useState(true);

	const handleOpen = (e, type) => {
		setPersonal(type)
		setOpen(true)
	}

	const handlePortal = async () => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_ENV_ENDPOINT}/api2/admin/portal`
				, {
					headers: {
						Authorization: `Bearer ${globalState.state.jwt}`
					}
				})

			window.location.href = res.data.url 
		}
		catch(e) {
			console.log(e.response)
		}
	}

	const handleGet = async () => {
		console.log('triggered get')
		const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/admin/profile`,
			{
				headers: {
					Authorization: `Bearer ${globalState.state.jwt}`,
				},
			}
		);
		console.log(res)

		// {
		// 	"_id": "61de91af18c0aa4936004a71",
		// 		"email": "test@gmail.com",
		// 			"password": "$2b$10$QGBI21sVuYtuqjV9LnBeieU.LPy..yZCNJb0bIEwPM1TqyWKRCV1O",
		// 				"customer_id": "cus_Kww65xJToe3fgt",
		// 					"active": false,
		// 						"createdAt": "2022-01-12T08:30:39.989Z",
		// 							"updatedAt": "2022-01-12T08:30:39.989Z",
		// 								"__v": 0
		// }


		setData(res.data)
	}

	useEffect(() => {
		if(data.length==0) {
			handleGet()
		}
	}, [JSON.stringify(data)])


	return (
		<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
			<Grid container spacing={3}>
				{/* Chart */}
				<Grid item xs={12} md={8} lg={9}>
					{data&&
					<Paper
						sx={{
							p: 2,
							display: 'flex',
							flexDirection: 'column',
							height: 240,
						}}
					>
						{/* <Typography>Email: {data.email}</Typography>
						<Typography>Contact Name: {data.contact_name}</Typography> */}
							<Title>Personal Information</Title>
							<Table size="small">
								<TableBody>
									{/* {rows.map((row) => ( */}
										<TableRow key={"email"}>
											<TableCell>{"Email"}</TableCell>
											<TableCell>{data.email}</TableCell>
										</TableRow>
									<TableRow key={"name"}>
										<TableCell>{"Contact Name"}</TableCell>
										<TableCell>{data.contact_name}</TableCell>
									</TableRow>
									{/* ))} */}
								</TableBody>
							</Table>
						<Button
					fullWidth
					// variant="contained"
					onClick={(e) => { handleOpen(e, true) }}
					sx={{ mt: "auto" }}
				>
					Update Personal Info
					</Button>	
					</Paper>
				}

				</Grid>
				{/* Recent Deposits */}
				<Grid item xs={12} md={4} lg={3}>
					<Paper
						sx={{
							p: 2,
							display: 'flex',
							flexDirection: 'column',
							height: 240,
						}}
					>
						You may update your billing information, view your subscription or
						download your invoice/invoice history at our portal.
						<Button sx={{mt:"auto"}} onClick={handlePortal}>Portal</Button>
						{/* <Deposits /> */}
					</Paper>
				</Grid>
				{/* Recent Orders */}
				<Grid item xs={12}>
					{data &&
					<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
						{/* <Orders /> */}
						<Title>Business Information</Title>
						<Table size="small">
							<TableBody>
								{/* {rows.map((row) => ( */}
								<TableRow key={"company"}>
									<TableCell>{"Company Name"}</TableCell>
									<TableCell>{data.company_name}</TableCell>
								</TableRow>
								<TableRow key={"bn"}>
									<TableCell>{"Business Nature"}</TableCell>
									<TableCell>{capitalizeFirstWord(data.business_nature)}</TableCell>
								</TableRow>
								<TableRow key={"line1"}>
									<TableCell>{"Address Line 1"}</TableCell>
									<TableCell>{data.line1}</TableCell>
								</TableRow>
								<TableRow key={"line2"}>
									<TableCell>{"Address Line 2"}</TableCell>
									<TableCell>{data.line2}</TableCell>
								</TableRow>
								<TableRow key={"city"}>
									<TableCell>{"City"}</TableCell>
									<TableCell>{data.city}</TableCell>
								</TableRow>
								<TableRow key={"country"}>
									<TableCell>{"Country"}</TableCell>
									<TableCell>{data.country}</TableCell>
								</TableRow>
								{/* ))} */}
							</TableBody>
						</Table>
						<Button
					fullWidth
					// variant="contained"
					onClick={(e)=>{handleOpen(e, false)}}
					sx={{ mt: 5 }}
				>
					Update Business Information
					</Button>	
					</Paper>}
				</Grid>
			</Grid>
			<Copyright sx={{ pt: 4 }} />
			<UpdateProfileDialog open={open} setOpen={setOpen} personal={personal} handleGet={handleGet}/>
		</Container>
	)
}