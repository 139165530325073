import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export default function APICard(props) {
	const [hidden, setHidden] = React.useState(true)

	return (
		<Card sx={{ minWidth: 275 }}>
			<CardContent>
				<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
					Product: {props.one}
				</Typography>
				<Typography variant="h5" component="div">
					Usage: {props.four}
				</Typography>
				<Typography sx={{ mb: 1.5 }} color="text.secondary">
					Tier Description: {props.two}
				</Typography>
				<Typography variant="body2">
					Subscribed: {props.three}
				</Typography>
				{!hidden &&
				<Typography variant="body2">
					{props.hidden}
				</Typography>
				}
			</CardContent>
			<CardActions>
				<Button size="small" onClick={()=> {setHidden(!hidden)}}>{hidden?"Show":"Hide"} Key</Button>
			</CardActions>
		</Card>
	);
}