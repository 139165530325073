import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../components/Copyright';
import { store } from '../store/store';
import capitalizeFirstWord from '../utils/capitalizeFirstWord';
import axios from 'axios';


const theme = createTheme();

export default function Register() {
	const globalState = React.useContext(store);
	const { dispatch } = globalState;

	const handleBack = () => {
		dispatch({ type: "page", value: "subscribe" })
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		// eslint-disable-next-line no-console

		// for (var key of data.keys()) {
		// 	console.log(key);
		// }

		const res = await axios.post(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/auth/register`, {
			email: data.get(`email`),
			password: data.get(`password`),
			contact: data.get(`contact`),
			// tier: data.get(`tier`)
		})

		// if (data.get(`tier`) !== `free`) {window.location.href = res.data.url}
		
		dispatch({ type: "page", value: "login" })

	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Registration
					</Typography>
					<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							{/*<Grid item xs={12} sm={6}>
								<TextField
									required
									fullWidth
									id="lastName"
									label="Last Name"
									name="lastName"
									autoComplete="family-name"
								/>
							</Grid> */}	
							<Grid item xs={12}>
								<TextField
									autoComplete="contact"
									name="contact"
									required
									fullWidth
									id="contact"
									label="Contact Name"
									autoFocus
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="new-password"
								/>
							</Grid>
							<Grid item xs={12}>
								
								<TextField
									required
									fullWidth
									// name="password"
									label="Repeat Password"
									type="password"
									// autoComplete="new-password"
								/>
							</Grid>

							{/* <Grid item xs={12}>
								<input type="hidden" name="tier" value={globalState.state.tier} />
								<TextField
									// required
									disabled
									readOnly
									fullWidth
									value={capitalizeFirstWord(globalState.state.tier)}
									label="Tier"
								/>
							</Grid> */}
							{/* <Grid item xs={12}>
								<FormControlLabel
									control={<Checkbox value="allowExtraEmails" color="primary" />}
									label="I want to receive inspiration, marketing promotions and updates via email."
								/>
							</Grid> */}
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign Up
						</Button>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Link onClick={handleBack} 
								// href="#" 
								variant="body2">
									Already have an account? Sign in
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 5 }} />
			</Container>
		</ThemeProvider>
	);
}