import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Container, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Copyright from '../components/Copyright';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { store } from '../store/store';
import axios from 'axios';
import CountrySelect from '../components/CountrySelect';

const theme = createTheme();

export default function Company() {
	const globalState = React.useContext(store);
	const { dispatch } = globalState;
	const [bn, setBn] = React.useState("")
	const [country, setCountry] = React.useState("")

	const handleSubmit = async (event) => {
		event.preventDefault();

		const data = new FormData(event.currentTarget);
		console.log(data.get('bn'))
		try {
			const res = await axios.post(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/admin/user`, {
				company_name: data.get('company'),
				business_nature: bn,
				country,
				line1: data.get('address1'),
				line2: data.get('address2'),
				city: data.get('city')
			},
			{
				headers: {
					Authorization: `Bearer ${globalState.state.jwt}`
				}
			})
			console.log(res.data)

			dispatch({ type: "companyName", value: res.data.company_name })

		} catch (e) {
			console.log(e.message)
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Hi, you've subscribed to us and we'd like to know a bit more about you.
					</Typography>
					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							id="company"
							label="Company Name"
							name="company"
							autoComplete="company"
							autoFocus
						/>
						<FormControl fullWidth>
							<InputLabel id="bn-label">Business Nature</InputLabel>
							<Select
								labelId="bn-label"
								id="bn"
								value={bn}
								label="Business Nature"
								onChange={(e)=>{setBn(e.target.value)}}
							>
								<MenuItem value={"accounting"}>Accounting Services</MenuItem>
								<MenuItem value={"administrative"}>Administrative and Support Services</MenuItem>
								<MenuItem value={"hotel"}>Service Apartment, Hostel and Hotel</MenuItem>
								<MenuItem value={"apparel"}>Apparel</MenuItem>
								<MenuItem value={"entertainment"}>Arts, Entertainment and Recreation</MenuItem>
								<MenuItem value={"baby_kids"}>Baby and Kids</MenuItem>
								<MenuItem value={"finance"}>Banking, Finance and Insurance</MenuItem>
								<MenuItem value={"camera"}>Camera and Optics</MenuItem>
								<MenuItem value={"education"}>Education</MenuItem>
								<MenuItem value={"electronics"}>Electronics and Electrical Appliances</MenuItem>
								<MenuItem value={"foods"}>Food, Beverage and Tobacco</MenuItem>
								<MenuItem value={"furniture"}>Furniture</MenuItem>
								<MenuItem value={"government"}>Government</MenuItem>
								<MenuItem value={"health"}>Health and Beauty</MenuItem>
								<MenuItem value={"home_goods"}>Home and Garden</MenuItem>
								<MenuItem value={"it"}>Information Technology and Telecommunications</MenuItem>
								<MenuItem value={"land_development"}>Land Development and Real Estate Services</MenuItem>
								<MenuItem value={"legal"}>Legal Services</MenuItem>
								<MenuItem value={"luggage"}>Luggage and Bag</MenuItem>
								<MenuItem value={"ngo"}>NGO</MenuItem>
								<MenuItem value={"office_supplies"}>Office Supplies</MenuItem>
								<MenuItem value={"restaurant"}>Restaurant</MenuItem>
								<MenuItem value={"sporting_goods"}>Sporting Goods</MenuItem>
								<MenuItem value={"toys_games"}>Toys and Games</MenuItem>
								<MenuItem value={"utilities"}>Utilities</MenuItem>
								<MenuItem value={"other"}>Other</MenuItem>
								{/* <MenuItem value={30}>Thirty</MenuItem> */}
							</Select>
						</FormControl>

						<CountrySelect setCountry={setCountry}/>
						<TextField
							sx={{ mt: 1 }}
							required
							fullWidth
							id="address1"
							label="Address Line 1"
							name="address1"
							autoComplete="address1"
							autoFocus
						/>
						<TextField
							sx={{ mt: 1 }}
							required
							fullWidth
							id="address2"
							label="Address Line 2"
							name="address2"
							autoComplete="address2"
							autoFocus
						/>
						<TextField
							sx={{ mt: 1 }}
							required
							fullWidth
							id="city"
							label="City"
							name="city"
							autoComplete="city"
							autoFocus
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Continue Forth!
						</Button>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</ThemeProvider>
	);
}