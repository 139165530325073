import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Copyright from '../components/Copyright';
import { store } from '../store/store';
import axios from 'axios';
import ConfirmDialog from '../components/ConfirmDialog';
import tierProduct from '../utils/tierProduct';

const tiers = [
	{
		title: 'Business',
		price: '80',
		code:'2',
		description: [
			'Web upload',
			'API call',
			'120 scans/month',
			'Extra scans @HK$0.63',
		],
		buttonText: 'Order Now',
		buttonVariant: 'outlined',
	},
	{
		title: 'Enterprise',
		code:'3',
		subheader: 'Most popular',
		price: '400',
		description: [
			'Web upload',
			'API call',
			'900 scans/month',
			'Extra scans @HK$0.52',
		],
		buttonText: 'Order Now',
		buttonVariant: 'contained',
	},
	{
		title: 'Professional',
		code:'4',
		price: '2500',
		description: [
			'Web upload',
			'API call',
			'8000 scans/month',
			'Extra scans @HK$0.38',
		],
		buttonText: 'Order Now',
		buttonVariant: 'outlined',
	},
];

const footers = [
	{
		title: 'Company',
		description: ['Team', 'History', 'Contact us', 'Locations'],
	},
	{
		title: 'Features',
		description: [
			'Cool stuff',
			'Random feature',
			'Team feature',
			'Developer stuff',
			'Another one',
		],
	},
	{
		title: 'Resources',
		description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
	},
	{
		title: 'Legal',
		description: ['Privacy policy', 'Terms of use'],
	},
];

function PricingContent() {
	const globalState = React.useContext(store);
	const { dispatch } = globalState;
	const [open, setOpen] = React.useState(false);
	const [pot, setPot] = React.useState("");
	const [current, setCurrent] = React.useState("");
	const [date, setDate] = React.useState("");

	const handleConfirm = (tier) => {
		setPot(tier);
		setOpen(true)
	}

	const handleUpgrade = async () => {
		// if (tier!=="enterprise") {
		// 	dispatch({ type: "tier", value: tier })
		// 	dispatch({ type: "page", value: "register" })
		// }
		const res = await axios.post(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/pay/upgrade`,
			{ tier:pot },
			{
				headers: {
					Authorization: `Bearer ${globalState.state.jwt}`,
				},
			}
		);
		console.log(res)

		if (res.status === 200) {
			setOpen(false)
			dispatch({
				type: "alert", value: {
					color: "green",
					message: "Upgrade success, you will be billed at your billing period with the upgraded per call cost!",
					open: true
				}
			})
		}
		if (res.status === 400) {
			dispatch({
				type: "alert", value: {
					color: "red",
					message: "An error has occurred, please retry action or contact an administrator",
					open: true
				}
			})
		}
	}

	const handleGet = async () => {
		console.log('triggered get')
		const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/pay/get_sub?product=invoice`,
			{
				headers: {
					Authorization: `Bearer ${globalState.state.jwt}`,
				},
			}
		);

		// console.log(JSON.stringify(res.data.faq, null, 4))
		console.log(res.data)
		setDate(new Date(res.data.createdAt).toLocaleString('en-HK'))
		setCurrent(res.data.tier)
		// setData(res.data)
		// array of
		// {
		// 	"_id": "61de91db18c0aa4936004a76",
		// 		"user_id": "61de91af18c0aa4936004a71",
		// 			"usage": 0,
		// 				"product": "invoice",
		// 					"apiKey": "cacad9deca34554e0dab9b2bef39c40e",
		// 						"tier": "1",
		// 							"createdAt": "2022-01-12T08:31:23.583Z",
		// 								"updatedAt": "2022-01-12T08:31:23.583Z",
		// 									"__v": 0
		// }
	}

	React.useEffect(() => {
		handleGet()
	}, [])



	return (
		<React.Fragment>
			<GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
			<CssBaseline />
			{/* <AppBar
				position="static"
				color="default"
				elevation={0}
				sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
			>
				<Toolbar sx={{ flexWrap: 'wrap' }}>
					<Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
						Company name
					</Typography>
					<nav>
						<Link
							variant="button"
							color="text.primary"
							href="#"
							sx={{ my: 1, mx: 1.5 }}
						>
							Features
						</Link>
						<Link
							variant="button"
							color="text.primary"
							href="#"
							sx={{ my: 1, mx: 1.5 }}
						>
							Enterprise
						</Link>
						<Link
							variant="button"
							color="text.primary"
							href="#"
							sx={{ my: 1, mx: 1.5 }}
						>
							Support
						</Link>
					</nav>
					<Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
						Login
					</Button>
				</Toolbar>
			</AppBar> */}
			{/* Hero unit */}
			<Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
				<Typography
					component="h1"
					variant="h2"
					align="center"
					color="text.primary"
					gutterBottom
				>
					aInvoice
				</Typography>
				<Typography variant="h5" align="center" color="text.secondary" component="p">
					aINVOICE uses the most advanced  AI OCR, NLP and algorithm to extract and analyze fields, table and data from an invoice.
					Automate your business process and streamline accounting software today!
				</Typography>
			</Container>
			{/* End hero unit */}
			<Container maxWidth="md" component="main">
				<Grid container spacing={5} alignItems="flex-end">
					{tiers.map((tier) => (
						// Enterprise card is full width at sm breakpoint
						<Grid
							item
							key={tier.title}
							xs={12}
							sm={tier.title === 'Enterprise' ? 12 : 12}
							md={4}
						>
							<Card>
								<CardHeader
									title={tier.title}
									subheader={tier.subheader}
									titleTypographyProps={{ align: 'center' }}
									action={tier.title === 'Enterprise' ? <StarIcon /> : null}
									subheaderTypographyProps={{
										align: 'center',
									}}
									sx={{
										backgroundColor: (theme) =>
											theme.palette.mode === 'light'
												? theme.palette.grey[200]
												: theme.palette.grey[700],
									}}
								/>
								<CardContent>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'baseline',
											mb: 2,
										}}
									>
										<Typography component="h2" variant="h4" color="text.primary">
											HK${tier.price}
										</Typography>
										<Typography variant="h6" color="text.secondary">
											/mo
										</Typography>
									</Box>
									<ul>
										{tier.description.map((line) => (
											<Typography
												component="li"
												variant="subtitle1"
												align="center"
												key={line}
											>
												{line}
											</Typography>
										))}
									</ul>
								</CardContent>
								<CardActions>
									<Button fullWidth variant={tier.buttonVariant} onClick={() => {handleConfirm(tier.code.toLowerCase())}}>
										{tier.buttonText}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
			{/* Footer */}
			{/* <Container
				maxWidth="md"
				component="footer"
				sx={{
					borderTop: (theme) => `1px solid ${theme.palette.divider}`,
					mt: 8,
					py: [3, 6],
				}}
			>
				<Grid container spacing={4} justifyContent="space-evenly">
					{footers.map((footer) => (
						<Grid item xs={6} sm={3} key={footer.title}>
							<Typography variant="h6" color="text.primary" gutterBottom>
								{footer.title}
							</Typography>
							<ul>
								{footer.description.map((item) => (
									<li key={item}>
										<Link href="#" variant="subtitle1" color="text.secondary">
											{item}
										</Link>
									</li>
								))}
							</ul>
						</Grid>
					))}
				</Grid>
				<Copyright sx={{ mt: 5 }} />
			</Container> */}
			{/* End footer */}
			{ pot && current && date &&
			<ConfirmDialog open={open} setOpen={setOpen} before={tierProduct(current, 'invoice')} after={tierProduct(pot, 'invoice')} handleUpgrade={handleUpgrade} 
			createdAt={date}
			/>
			}
		</React.Fragment>
	);
}

export default function Upgrade() {
	return <PricingContent />;
}