import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import format from "../utils/format";
import axios from 'axios';
import { Box, DialogContentText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

function PaperComponent(props) {
	return (
		<Draggable
			handle="#confirm-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	);
}

export default function ConfirmDialog(props) {
	
	// React.useEffect(() => {
	// 	// console.log(props)
	// 	setEdit(props.dialogEdit)
	// }, [props])

	const handleClose = () => {
		props.setOpen(false);
	};

	const handleSave = () => {
		// props.handleDialogEdit(edit);
		props.setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				PaperProps={{
					sx : {maxWidth:10000}
				}}
				PaperComponent={PaperComponent}
				aria-labelledby="confirm-dialog-title"
			>
				<Box 
				// component="form" noValidate onSubmit={(e) => { e.preventDefault(); }} 
				sx={{ mt: 3 }}>
					<DialogTitle style={{ cursor: 'move' }} id="confirm-dialog-title">
						Confirm Upgrade?
					</DialogTitle>

					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell>Package Name</TableCell>
									<TableCell align="right">Web Upload</TableCell>
									<TableCell align="right">API Call</TableCell>
									<TableCell align="right">Calls/Month</TableCell>
									<TableCell align="right">Per Call Over Limit</TableCell>
									<TableCell align="right">Price/Month</TableCell>
									<TableCell align="right">Subscribed On</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow
									key={props.before.name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>Current Plan</TableCell>
									<TableCell  component="th" scope="row">
										{props.before.name}
									</TableCell>
									<TableCell align="center">{props.before.webUpload ? <DoneIcon sx={{color:"green"}}/>: <CloseIcon/>}</TableCell>
									<TableCell align="center">{props.before.apiCall ? <DoneIcon sx={{ color: "green" }}/> : <CloseIcon />}</TableCell>
									<TableCell align="center">{props.before.callsMonth}</TableCell>
									<TableCell align="center">{props.before.perCallOverLimit}</TableCell>
									<TableCell align="center">{props.before.priceMonth}</TableCell>
									<TableCell align="center">{props.createdAt}</TableCell>
								</TableRow>
								
								<TableRow
									key={props.after.name}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>After Upgrade</TableCell>
									<TableCell component="th" scope="row">
										{props.after.name}
									</TableCell>
									<TableCell align="center">{props.after.webUpload ? <DoneIcon sx={{ color: "green" }} /> : <CloseIcon />}</TableCell>
									<TableCell align="center">{props.after.apiCall ? <DoneIcon sx={{ color: "green" }} /> : <CloseIcon />}</TableCell>
									<TableCell align="center">{props.after.callsMonth}</TableCell>
									<TableCell align="center">{props.after.perCallOverLimit}</TableCell>
									<TableCell align="center">{props.after.priceMonth}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>

					{/* <DialogContent> */}
						{/* <DialogContentText> */}
						{/* {props.before}  */}
					{/* CURRENT */}
						{/* </DialogContentText> */}
						{/* <DialogContentText>
					props.information AFTER
					</DialogContentText> */}
					{/* </DialogContent> */}
					<DialogActions>
						<Button autoFocus onClick={handleClose}>
							Go Back
						</Button>
						<Button onClick={props.handleUpgrade} autoFocus>
							Upgrade!
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
		</div>
	);
}


//