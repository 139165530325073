import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import format from "../utils/format";
import axios from 'axios';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import CountrySelect from './CountrySelect';
import Title from './Title';
import { store } from '../store/store';

function PaperComponent(props) {
	return (
		<Draggable
			handle="#update-info-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	);
}

export default function UpdateProfileDialog(props) {
	const globalState = React.useContext(store);
	const [bn, setBn] = React.useState("")
	const [country, setCountry] = React.useState("")

	const handleUpdate = async (event) => {
		event.preventDefault()

		const data = new FormData(event.currentTarget);

		var obj = {}
		for(var key of data.keys()) {
			if (data.get(key)) {
				obj[key] = data.get(key)
			}
		}
		if (bn) {
			obj['business_nature'] = bn
		}
		if (country) {
			obj['country'] = country
		}

		console.log(obj)
		const res = await axios.post('https://product.chat/api2/admin/user', 
		obj,
		{
			headers: {
				Authorization: `Bearer ${globalState.state.jwt}`,
			},
		}
		)

		console.log(res)
		if (res.status === 200) {
			props.handleGet()	
			props.setOpen(false)
		} else {
			console.log("Add Error")
		}
	}
	
	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				PaperComponent={PaperComponent}
				aria-labelledby="update-info-dialog-title"
			>

				{props.personal === true ? (
					<Box component="form" noValidate onSubmit={handleUpdate} sx={{ mt: 3 }}>
						<DialogTitle style={{ cursor: 'move' }} id="update-info-dialog-title">
							<Title>Personal Information</Title>
						</DialogTitle>

						<DialogContent>
							{/* <DialogContentText>
							To subscribe to this website, please enter your email line here. We
							will send updates occasionally.
						</DialogContentText> */}
							<TextField
								margin="normal"
								autoComplete="contact_name"
								name="contact_name"
								fullWidth
								id="contact_name"
								label="Contact Name"
								autoFocus
							/>

							{/* <TextField
								sx={{ mt: 1 }}
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								disabled
							/> */}

							<TextField
								sx={{ mt: 1 }}
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="new-password"
							/>


							<TextField
								sx={{ mt: 1 }}
								required
								fullWidth
								// name="password"
								label="Repeat Password"
								type="password"
							// autoComplete="new-password"
							/>
						
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={handleClose}>
								Cancel
							</Button>
							<Button type="submit" autoFocus>
								Update
							</Button>
						</DialogActions>
					</Box>
				) :
				(
					<Box component="form" noValidate onSubmit={handleUpdate} sx={{ mt: 3 }}>
						<DialogTitle style={{ cursor: 'move' }} id="update-info-dialog-title">
							<Title>Business Information</Title>
						</DialogTitle>
						<DialogContent>
								<TextField
									margin="normal"
									fullWidth
									id="company_name"
									label="Company Name"
									name="company_name"
									autoComplete="company_name"
									autoFocus
								/>
								<FormControl fullWidth>
									<InputLabel id="bn-label">Business Nature</InputLabel>
									<Select
										labelId="bn-label"
										id="bn"
										value={bn}
										label="Business Nature"
										onChange={(e) => { setBn(e.target.value) }}
									>
										<MenuItem value={"accounting"}>Accounting Services</MenuItem>
										<MenuItem value={"administrative"}>Administrative and Support Services</MenuItem>
										<MenuItem value={"hotel"}>Service Apartment, Hostel and Hotel</MenuItem>
										<MenuItem value={"apparel"}>Apparel</MenuItem>
										<MenuItem value={"entertainment"}>Arts, Entertainment and Recreation</MenuItem>
										<MenuItem value={"baby_kids"}>Baby and Kids</MenuItem>
										<MenuItem value={"finance"}>Banking, Finance and Insurance</MenuItem>
										<MenuItem value={"camera"}>Camera and Optics</MenuItem>
										<MenuItem value={"education"}>Education</MenuItem>
										<MenuItem value={"electronics"}>Electronics and Electrical Appliances</MenuItem>
										<MenuItem value={"foods"}>Food, Beverage and Tobacco</MenuItem>
										<MenuItem value={"furniture"}>Furniture</MenuItem>
										<MenuItem value={"government"}>Government</MenuItem>
										<MenuItem value={"health"}>Health and Beauty</MenuItem>
										<MenuItem value={"home_goods"}>Home and Garden</MenuItem>
										<MenuItem value={"it"}>Information Technology and Telecommunications</MenuItem>
										<MenuItem value={"land_development"}>Land Development and Real Estate Services</MenuItem>
										<MenuItem value={"legal"}>Legal Services</MenuItem>
										<MenuItem value={"luggage"}>Luggage and Bag</MenuItem>
										<MenuItem value={"ngo"}>NGO</MenuItem>
										<MenuItem value={"office_supplies"}>Office Supplies</MenuItem>
										<MenuItem value={"restaurant"}>Restaurant</MenuItem>
										<MenuItem value={"sporting_goods"}>Sporting Goods</MenuItem>
										<MenuItem value={"toys_games"}>Toys and Games</MenuItem>
										<MenuItem value={"utilities"}>Utilities</MenuItem>
										<MenuItem value={"other"}>Other</MenuItem>
										{/* <MenuItem value={30}>Thirty</MenuItem> */}
									</Select>
								</FormControl>

								<CountrySelect setCountry={setCountry} />
								<TextField
									sx={{ mt: 1 }}
									fullWidth
									id="line1"
									label="Address Line 1"
									name="line1"
									autoComplete="line1"
									autoFocus
								/>
								<TextField
									sx={{ mt: 1 }}
									fullWidth
									id="line2"
									label="Address Line 2"
									name="line2"
									autoComplete="line2"
									autoFocus
								/>
								<TextField
									sx={{ mt: 1 }}
									fullWidth
									id="city"
									label="City"
									name="city"
									autoComplete="city"
									autoFocus
								/>
							
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={handleClose}>
								Cancel
							</Button>
							<Button type="submit" autoFocus>
								Update
							</Button>
						</DialogActions>
					</Box>
				)}
			</Dialog>
		</div>
	);
}


//