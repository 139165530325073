export default function format(str, type) {
	switch (str) {
		case 'id': {
			return type === "name" ? 'ID' : 150
		}
		case 'question_qid': {
			return type === "name" ? 'ID' : 150
		}
		case 'answer_en': {
			return type === "name" ? 'Answer (En)' : 150
		}
		case 'answer_tc': {
			return type === "name" ? 'Answer (TC)' : 150
		}
		case 'key_terms_en': {
			return type === "name" ? 'Key Terms (En)' : 150
		}
		case 'question_en': {
			return type === "name" ? 'Question (En)' : 150
		}
		case 'question_tc': {
			return type === "name" ? 'Question (TC)' : 150
		}
		case 'subject_entity_en': {
			return type === "name" ? 'Subject Entity (En)' : 150
		}
		case 'subject_entity_tc': {
			return type === "name" ? 'Subject Entity (TC)' : 150
		}
		case 'key_terms_tc': {
			return type === "name" ? 'Key Terms (TC)' : 150
		}
		default: {
			return type ==="name" ? str : 150
		}
	}
}