import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import BarChartIcon from '@mui/icons-material/BarChart';
import UploadIcon from '@mui/icons-material/Upload';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import QuizIcon from '@mui/icons-material/Quiz';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { store } from '../store/store';

export const ListItems = (props) => {
	const globalState = React.useContext(store);
	const { dispatch } = globalState;

	const handleClick = (value) => {
		dispatch({type:"page", value})
	} 


	return (
	props.type === "main" ? 
	<List>
		<div>
			<ListItem button onClick={() => { handleClick("dashboard") }}>
				<ListItemIcon>
					<DashboardIcon />
				</ListItemIcon>
				<ListItemText primary="Dashboard" />
			</ListItem>
			<ListItem button onClick={() => { handleClick("profile") }}>
				<ListItemIcon>
					<PersonIcon />
				</ListItemIcon>
				<ListItemText primary="Profile" />
			</ListItem>
			{/* <ListItem button onClick={()=>{handleClick("upload")}}>
				<ListItemIcon>
					<UploadIcon />
				</ListItemIcon>
				<ListItemText primary="Upload" />
			</ListItem>
			<ListItem button onClick={() => { handleClick("questions") }}>
				<ListItemIcon>
					<QuizIcon />
				</ListItemIcon>
				<ListItemText primary="Questions" />
			</ListItem> */}
			<ListItem button onClick={() => { handleClick("aPI Keys") }}>
				<ListItemIcon>
					<KeyIcon />
				</ListItemIcon>
				<ListItemText primary="API Keys" />
			</ListItem>
			<ListItem button onClick={() => { handleClick("user Guides") }}>
				<ListItemIcon>
					<ContactPageIcon />
				</ListItemIcon>
				<ListItemText primary="User Guide" />
			</ListItem>
		</div> 
	</List>
	:
	<List>
		<div>
			<ListSubheader inset>Upgrade/Other Products</ListSubheader>
				<ListItem button onClick={() => { handleClick("upgrade") }}>
					<ListItemIcon>
						<VisibilityIcon />
					</ListItemIcon>
					<ListItemText primary="Invoice Recognition" />
				</ListItem>
				{/* <ListItem button onClick={() => { handleClick("subscribe") }}>
					<ListItemIcon>
						<QuickreplyIcon />
					</ListItemIcon>
					<ListItemText primary="Chatbot" />
				</ListItem>
				<ListItem button onClick={() => { handleClick("subscribe") }}>
					<ListItemIcon>
						<WhatsAppIcon />
					</ListItemIcon>
					<ListItemText primary="WhatsApp" />
				</ListItem> */}

			{/* <ListItem button>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary="Current month" />
			</ListItem>
			<ListItem button>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary="Last quarter" />
			</ListItem>
			<ListItem button>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary="Year-end sale" />
			</ListItem> */}
		</div>
	</List>
)};