import { useContext } from "react";
import { store } from "../store/store";

import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {ListItems} from '../components/ListItems';
import capitalizeFirstWord from "../utils/capitalizeFirstWord";
import Dashboard from "./Dashboard";
import Upload from "./Upload";
import Table from "./Table";
import API from "./API";
import Upgrade from "./Upgrade";
import Profile from "./Profile";
import LogoutIcon from '@mui/icons-material/Logout';
import Guide from "./Guide";


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);



const mdTheme = createTheme();

function MainframeContent() {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} sx={{ backgroundColor: '#fa4d22'}}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {capitalizeFirstWord(globalState.state.page)}
            </Typography>
            <Typography
              // component="h1"
              variant="h6"
            // color="inherit"
            // noWrap
            sx={{ mr:3 }}
            >
              {`BizByAI Admin Portal`}
            </Typography>
            <IconButton color="inherit" onClick={() => { localStorage.removeItem('token'); dispatch({type:"logout", value:true})}}>
              {/* <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge> */}           
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
            <ListItems type="main"/>
          <Divider />
           <ListItems/>
        </Drawer>
       

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >

          <Toolbar />

          {globalState.state.page === "upload" ?
            <Upload/>
          :
          globalState.state.page === "questions" ?
            <Table />
          :
          globalState.state.page === "aPI Keys" ?
            <API />
          :
          globalState.state.page === "user Guides" ?
          <Guide />
          :
          globalState.state.page === "upgrade" ?
            <Upgrade />
          :
          globalState.state.page === "profile" ?
            <Profile />
          :
          <Dashboard/>
          }


        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Mainframe() {	
  return <MainframeContent />;
}
