import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import { Box } from '@mui/system';
import { CardMedia, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';



export default function GuideCard(props) {
	const theme = useTheme();

	const [hidden, setHidden] = React.useState(true)


	async function handleDownload(url) {
		const res = await axios.get(`https://product.chat/api2/guides/${url}`)
	}

	return (
		<Card sx={{ display: 'flex' }}>
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
		  <CardContent sx={{ flex: '1 0 auto' }}>
			<Typography component="div" variant="h5">
			  {props.title}
			</Typography>
			<Typography variant="subtitle1" color="text.secondary" component="div">
			  {props.desc}
			</Typography>
		  </CardContent>
		  <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
			<IconButton onClick={() => {handleDownload(props.url)}}>
			  <DownloadIcon sx={{ height: 38, width: 38 }} />
			</IconButton>
		  </Box>
		</Box>
		<CardMedia
		  component="img"
		  sx={{ width: 151, ml:"auto" }}
		  image={`https://product.chat/fs/public/guides/${props.media}`}
		  alt="Guide Image"
		/>
	  </Card>
	);
}