import { Alert, Button, Container, Grid, IconButton, Paper } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import Copyright from "../components/Copyright";
// import { store } from "../store/store";
import DeleteIcon from '@mui/icons-material/Delete';	
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DraggableDialog from "../components/DraggableDialog";
import format from "../utils/format";
import AddIcon from '@mui/icons-material/Add';
import { store } from '../store/store';


// const rows = [
// 	{ id: 1, col1: 'Hello', col2: 'World', col3: ['a','b'] },
// 	{ id: 2, col1: 'DataGridPro', col2: 'is Awesome', col3: ['c', 'd'] },
// 	{ id: 3, col1: 'MUI', col2: 'is Amazing', col3: ['e', 'f'] },
// ];

// const columns = [
// 	{ field: 'col1', headerName: 'Column 1', width: 150, editable: true},
// 	{ field: 'col2', headerName: 'Column 2', width: 150, editable: true},
// 	{ field: 'col3', headerName: 'Column 3', width: 150, editable: true },
// 	{
// 		field: 'actions',
// 		type: 'actions',
// 		headerName: 'Actions',
// 		width: 100,
// 		cellClassName: 'actions',
// 		getActions: ({ id }) => {
			
// 			return [
// 			<IconButton onClick={()=> {console.log(id)}}>
// 				<EditIcon />
// 			</IconButton>,
// 			<IconButton onClick={()=> {console.log(id)}}>
// 				<DeleteIcon />
// 			</IconButton>
// 			]
// 		}
// 	}
// ];

function CustomToolbar(props) {
	return (
		<GridToolbarContainer >
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} />
			<GridToolbarColumnsButton/>
			<GridToolbarFilterButton/>
			<GridToolbarDensitySelector/>
			<Button startIcon={<AddIcon />} onClick={props.handleOpenAdd}>Add</Button>
		</GridToolbarContainer>
	);
}



export default function Table() {
	const globalState = useContext(store);
	const { dispatch } = globalState;
	const [editRowsModel, setEditRowsModel] = useState({});
	const [dialogEdit, setDialogEdit] = useState({});
	const [open, setOpen] = useState(false);
	const [col, setCol] = useState([]);
	const [row, setRow] = useState([]);
	const [data, setData] = useState([]);
	const [addMode, setAddMode] = useState(false);
	const [company, setCompany] = useState("");

	const handleEditRowsModelChange = React.useCallback((model) => {

		if (Object.keys(model).length > 0) {

			const question_id = Object.keys(model)[0]
			const terms = Object.values(model)[0]["key_terms_en"]  
			const entity = Object.values(model)[0]["subject_entity_en"]
			const termsC = Object.values(model)[0]["key_terms_tc"]
			const entityC = Object.values(model)[0]["subject_entity_tc"]

			if (terms && typeof model[question_id]["key_terms_en"]["value"]==="string") model[question_id]["key_terms_en"]["value"] = (model[question_id]["key_terms_en"]["value"]).split(",")
			if (entity && typeof model[question_id]["subject_entity_en"]["value"] === "string") model[question_id]["subject_entity_en"]["value"] = (model[question_id]["subject_entity_en"]["value"]).split(",")
			if (termsC && typeof model[question_id]["key_terms_tc"]["value"] === "string") model[question_id]["key_terms_tc"]["value"] = (model[question_id]["key_terms_tc"]["value"]).split(",")
			if (entityC && typeof model[question_id]["subject_entity_tc"]["value"] === "string") model[question_id]["subject_entity_tc"]["value"] = (model[question_id]["subject_entity_tc"]["value"]).split(",")
		}
		setEditRowsModel(model);
	}, []);

	const handleDialogEdit = React.useCallback(({ id, answer_en, answer_tc, question_en, question_tc, key_terms_en, subject_entity_en, key_terms_tc, subject_entity_tc}) => {
		var clone = [...row]
		const index = clone.findIndex((i) => i["id"] === id)
		clone[index] = {
			id, answer_en, answer_tc, question_en, question_tc, key_terms_en, subject_entity_en, key_terms_tc, subject_entity_tc
		}
		setRow(clone)

		var obj = {
				question_qid:id,
				answer_en,
				answer_tc,
				question_en,
				question_tc,
				key_terms_en,
				subject_entity_en,key_terms_tc, subject_entity_tc
		}
		
		// const update = async () => {
		// 	await handleUpdate(obj)
		// }
		handleUpdate(obj)
	}, []);


	// useEffect(() => {
	// 	if (dialogEdit && Object.keys(dialogEdit).length > 0) 
	// 	handleUpdate(dialogEdit)
	// }, [dialogEdit])

	const handleDelete = async (id) => {
		const obj = {
			company_id:company,
			faq: [id]
		}
		console.log(obj)
		const res=  await axios.post(`https://product.chat/flask/crud_es/delete`, obj)

		console.log(res)
		if (res.status === 200) await handleGet()
	}

	const handleUpdate = async (model) => {
		// console.log(model)
		const obj = {
			company_id:company,
			faq: [model]
		}

		console.log(obj)
		const res=  await axios.post(`https://product.chat/flask/crud_es/update`, obj)

		console.log(res)
		if(res.status === 200) {
			await handleGet()
		}
	}

	const handleGet = async () => {
		console.log('triggered get')
		const res = await axios.get(`https://product.chat/flask/crud_es/read/${company}`)
		// console.log(JSON.stringify(res.data.faq, null, 4))
		console.log(res.data.faq)
		setData(res.data.faq)
	}

	const handleOpenAdd = () => {
		setDialogEdit({})
		setAddMode(true)
		setOpen(true)
	}

	useEffect(() => {
		//REQUEST AN API for SCHEMA => Header/DataType

		
		if (data && data.length > 0) {
			console.log('triggered')
			const columnOrdering = ["question_qid", "question_en", "answer_en", "key_terms_en", "subject_entity_en", "question_tc", "answer_tc", "key_terms_tc", "subject_entity_tc"];

			const prospectiveHeader = Object.keys(data[0])
			.filter((x) => x !== "reference_number" && x !== "en-US_QA" && x!=='company_id')		
			.sort((a, b) => {
				return (
					columnOrdering.indexOf(a) - columnOrdering.indexOf(b)
				);
			})
			
			const columnBuilder = prospectiveHeader.map((header) => {
				//{ field: 'col1', headerName: 'Column 1', width: 150, editable: true},
				return {field:header==="question_qid"?"id":header, headerName:format(header, "name"), width: format(header,"width"), editable: header==="question_qid" ?false:true}
			})

			columnBuilder.push({
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				cellClassName: 'actions',
				getActions: ({ id }) => {

					return [
						<IconButton onClick={() => { setOpen(true) }}>
							<EditIcon />
						</IconButton>,
						<IconButton onClick={async () => { await handleDelete(id) }}>
							<DeleteIcon />
						</IconButton>
					]
				}
			})

			setCol(columnBuilder)

			const formatRowObject = data.map(({ question_qid, question_en, answer_en, key_terms_en, subject_entity_en, question_tc, answer_tc, key_terms_tc, subject_entity_tc}) => ({
				id: question_qid, question_en, answer_en, key_terms_en, subject_entity_en, question_tc, answer_tc, key_terms_tc, subject_entity_tc
			})) 
			setRow(formatRowObject)
		}
	}, [JSON.stringify(data)])

	React.useEffect(() => {
		async function checkCompany() {

			if (!globalState.state.company)
			try {
				const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/admin/company`, {
					headers: {
						Authorization: `Bearer ${globalState.state.jwt}`
					}
				})
				if (res.data.company_id) {
					setCompany(globalState.state.company)
				}

			} catch (e) {
				console.log(e.response)
			}
			else {
				setCompany(globalState.state.company)
			}
		}
		checkCompany()

	}, [])

	React.useEffect(()=> {
		if (company) {
			handleGet()
		}
	}, [company])

	return (
		<Container maxWidth="false" sx={{ mt: 4, mb: 4, maxWidth: "1400px" }}>
			<Grid container spacing={3}>
				{company?
				<>
				<Grid item xs={12} md={12} lg={12}>
					<Paper sx={{height:600, width:'100%'}}>
						<DataGrid 
							components={{
								Toolbar: () => {
									return (<CustomToolbar handleOpenAdd={handleOpenAdd} />)
								}
							}}
							loading={row && Object.keys(row).length > 0 ? false: true}
							rows={row}
							columns={col}
							editRowsModel={editRowsModel}
							editMode="row"
							onEditRowsModelChange={(e) => {
								handleEditRowsModelChange(e)
							}}
							onRowEditCommit={async () => { 
								//fire off edit to API
								// var rowId = Object.keys(editRowsModel)[0]
								var question_qid = Object.keys(editRowsModel)[0]
								var obj = Object.values(editRowsModel).map(({ answer_en, answer_tc, question_en, question_tc, key_terms_en, subject_entity_en, key_terms_tc, subject_entity_tc}) => ({
									question_qid,
									answer_en:answer_en.value,
									answer_tc: answer_tc.value,
									question_en: question_en.value,
									question_tc: question_tc.value,
									key_terms_en: key_terms_en.value,
									subject_entity_en: subject_entity_en.value,
									key_terms_tc: key_terms_tc.value, 
									subject_entity_tc: subject_entity_tc.value
								}))
								setAddMode(false)
								await handleUpdate(obj[0])
							}}
							onRowClick={(p, e)=> {
								setAddMode(false)
								setDialogEdit(p.row) //fire off to dialog edit
							}}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={12} lg={12}>
					<Paper>
						<Alert severity="info" style={{ marginTop: 8 }}>
							<code>editRowsModel: {JSON.stringify(editRowsModel)}</code>
						</Alert>
					</Paper>
				</Grid>
				{/*<Grid item xs={12} md={12} lg={12}>
					<Paper>

					</Paper>
				</Grid> */}
				</>
				:
				<Paper sx={{ height: 600, width: '100%', p: 0 }}>
					Woops, please Upload
				</Paper>}

			</Grid>
			<Copyright sx={{ pt: 4 }} />
			
			<DraggableDialog open={open} setOpen={setOpen} addMode={addMode} header={col} dialogEdit={dialogEdit} handleDialogEdit={handleDialogEdit} handleGet={handleGet} company={company} />
		</Container>
		
	)
}