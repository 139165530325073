import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import axios from 'axios';
import { store } from '../store/store';
import capitalizeFirstWord from "../utils/capitalizeFirstWord";

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    '16 Mar, 2019',
    'Elvis Presley',
    'Tupelo, MS',
    'VISA ⠀•••• 3719',
    312.44,
  ),
  createData(
    1,
    '16 Mar, 2019',
    'Paul McCartney',
    'London, UK',
    'VISA ⠀•••• 2574',
    866.99,
  ),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(
    3,
    '16 Mar, 2019',
    'Michael Jackson',
    'Gary, IN',
    'AMEX ⠀•••• 2000',
    654.39,
  ),
  createData(
    4,
    '15 Mar, 2019',
    'Bruce Springsteen',
    'Long Branch, NJ',
    'VISA ⠀•••• 5919',
    212.79,
  ),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Invoices() {
  const globalState = React.useContext(store);
  const { dispatch } = globalState;

  const [data, setData] = React.useState([]);

  const handleGet = async () => {
    // console.log('triggered get invoice')
    const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/dashboard/invoice/`,
      {
        headers: {
          Authorization: `Bearer ${globalState.state.jwt}`,
        },
      }
    );

    // console.log(JSON.stringify(res.data.faq, null, 4))
    // console.log(res.data)

    if (res.data) setData(res.data)
  }

  React.useEffect(() => {
    handleGet()
  }, [])
  return (
    <React.Fragment>
      <Title>Recent Invoices</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>Until</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{new Date(row.period_start *1000).toLocaleDateString('en-HK')}</TableCell>
              <TableCell>{new Date(row.period_end*1000).toLocaleDateString('en-HK')}</TableCell>
              <TableCell>{row.lines?.data[0]?.description}</TableCell>
              
              <TableCell>{capitalizeFirstWord(row.status)}</TableCell>
              <TableCell align="right">{`HK$${row.total/100}`}</TableCell>
            </TableRow>))
          :<></>
          }
        </TableBody>
      </Table>
      <Link color="primary" onClick={() => { dispatch({ type: "page", value:"profile" })}} sx={{ mt: 3 }}>
        Download or View more Invoices 
      </Link>
    </React.Fragment>
  );
}