import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { store } from '../store/store';
import axios from 'axios';

function preventDefault(event) {
  event.preventDefault();
}

export default function LatestSubscription() {
  const globalState = React.useContext(store);
  const { dispatch } = globalState;

  const [data, setData] = React.useState({});

  const handleGet = async () => {
    // console.log('triggered get latest')
    const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/dashboard/latest`,
      {
        headers: {
          Authorization: `Bearer ${globalState.state.jwt}`,
        },
      }
    );

    // console.log(JSON.stringify(res.data.faq, null, 4))
    // console.log(res.data)
    if(res.data) setData(res.data[0])
  }

  React.useEffect(() => {
    handleGet()
  }, [])

  return (
    <React.Fragment>
      <Title>Latest Subscription</Title>
      <Typography component="p" variant="h4">
        Usage: {data && data.monthly_usage}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1, mt:1 }}>
        {data && `Since Last Billing Period`}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        {data && new Date(data.createdAt).toLocaleString('en-HK')}
      </Typography> */}
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {data && `Product: ${data.product}`}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {data && `Tier: ${data.tier}`}
      </Typography>
      <div>
        <Link color="primary" onClick={() => { dispatch({ type: "page", value: "aPI Keys" }) }}>
          View API Keys
        </Link>
      </div>
    </React.Fragment>
  );
}