import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { store } from '../store/store';

export default function Alert() {
	const globalState = React.useContext(store);
	const [open, setOpen] = React.useState(false);
	const hi = React.useRef(null);


	function close() {
		const sup = hi.current
		sup.style.visibility = "hidden"
		// console.log(hi)
		// sup.children[0].children[0].textContent = "testing"
	}

	const handleClose = (event, reason) => {
		const { dispatch } = globalState;
		dispatch({type:"alert", value:{
			color:"black",
			message:"",
			open:false
		}})
		setOpen(false)
		// if (reason === 'clickaway') {
		// 	return;
		// }

		// close()
	};	// 	const sup = hi.current

	// React.useEffect(() => {
	// 	setTimeout(() => {
	// 		setOpen(true)
	// 		close()
	// 	}, 100)
	// }, [])

	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<div>
			{/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
			<Snackbar ref={hi}
				sx={{backgroundColor:globalState.state.alert.color}}
				open={globalState.state.alert.open}
				autoHideDuration={6000}
				onClose={handleClose}
				message={globalState.state.alert.message}
				action={action}
			/>
		</div>
	);
}