export default function tierProduct(tier, product) {
	switch (product) {
		case 'invoice': {
			if (tier === '2') {
				return { name: "aInvoice Business", webUpload: true, apiCall: true, callsMonth: "120", priceMonth: "HK$80", perCallOverLimit:"HK$0.63"}
			}
			if (tier === '3') {
				return { name: "aInvoice Ent", webUpload: true, apiCall: true, callsMonth: "900", priceMonth: "HK$400", perCallOverLimit: "HK$0.52" }
			}
			if (tier === '4') {
				return { name: "aInvoice Pro", webUpload: true, apiCall: true, callsMonth: "8000", priceMonth: "HK$2500", perCallOverLimit: "HK$0.38" }
			}
			break;
		}
		default: {
			return "Product Not Found"
		}
	}
}