// store.js
import React, { createContext, useReducer } from 'react';

const initialState = {
	// auth: "black",
	page: "",
	company: "",
	jwt: "",
	tier: "",
	companyName:"",
	alert: {
		message: "",
		open: false,
		color: "black"
	}
	// customerId: "",
	// apiKey: ""
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, action) => {

		var newState = {}
		// SAMPLE METHOD dispatch({ type: "page", value: "home" })
		switch (action.type) {
			case 'logout': {
				return initialState
			}
			default: {
				if (!Object.keys(initialState).includes(action.type))
					throw new Error();
				newState = Object.keys(initialState).includes(action.type) ? {
					...state,
					[action.type]: action.value
				} : newState
				return newState
			}
			// case 'page':
			// 	newState = {
			// 		...state,
			// 		page: action.value
			// 	}
			// 	return newState;
			// case 'customerId':
			// 	newState = {
			// 		...state,
			// 		customerId: action.value
			// 	}
			// 	return newState;
			// case 'name':
			// 	newState = {
			// 		...state,
			// 		name: action.value
			// 	}
			// 	return newState;
			// case 'apiKey':
			// 	newState = {
			// 		...state,
			// 		apiKey: action.value
			// 	}
			// 	return newState;
			// default:
			// 	throw new Error();
		};
	}, initialState);

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }