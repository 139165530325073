

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import Title from './Title';
import { store } from '../store/store';
import axios from 'axios';

// Generate Sales Data
// function createData(time, amount) {
// 	return { time, amount };
// }

// const data = [
// 	createData('00:00', 0),
// 	createData('03:00', 50),
// 	createData('06:00', 100),
// 	createData('09:00', 200),
// 	createData('12:00', 400),
// 	createData('15:00', 600),
// 	createData('18:00', 900),
// 	createData('21:00', 1000),
// 	createData('24:00', 1000),
// ];

export default function Chart() {
	const theme = useTheme();
	const globalState = React.useContext(store);

	const [data, setData] = React.useState([]);

	const handleGet = async () => {
		// console.log('triggered get graph')
		const res = await axios.get(`${process.env.REACT_APP_ENV_ENDPOINT}/api2/dashboard/graph`,
			{
				headers: {
					Authorization: `Bearer ${globalState.state.jwt}`,
				},
			}
		);

		// console.log(JSON.stringify(res.data.faq, null, 4))
		// console.log(res.data)
		
		const format = res.data.map((point) => {


			return {time: point.date.slice(11,16), amount: point.count}
		}) 

		setData(format)
	}

	React.useEffect(() => {
		handleGet()
	}, [])

	return (
		<React.Fragment>
			<Title>Today</Title>
			<ResponsiveContainer>
				<LineChart
					data={data}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}}
				>
					<XAxis
						dataKey="time"
						stroke={theme.palette.text.secondary}
						style={theme.typography.body2}
					/>
					<YAxis
						stroke={theme.palette.text.secondary}
						style={theme.typography.body2}
						allowDecimals={false}
					>
						<Label
							angle={270}
							position="left"
							style={{
								textAnchor: 'middle',
								fill: theme.palette.text.primary,
								...theme.typography.body1,
							}}
						>
							API Calls
						</Label>
					</YAxis>
					<Line
						isAnimationActive={false}
						type="monotone"
						dataKey="amount"
						stroke={theme.palette.primary.main}
						dot={false}
					/>
				</LineChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
}