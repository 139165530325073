import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import format from "../utils/format";
import axios from 'axios';
import { Box } from '@mui/material';

function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	);
}

export default function DraggableDialog(props) {
	const [edit, setEdit] = React.useState({})
	const [add, setAdd] = React.useState({})

	React.useEffect(()=> {
		// console.log(props)
		setEdit(props.dialogEdit)
	}, [props])

	// React.useEffect(()=> {
	// 	if (edit&& Object.keys(edit).length>0) console.log(edit)
	// },[edit])
	const handleAdd = async () => {
		console.log(add)
		const res = await axios.post('https://product.chat/flask/crud_es/add', {
			company_id: props.company,
			faq: [add]
		})

		console.log(res)
		if (res.status === 200) {
			setAdd({})
			await props.handleGet()
			handleClose()
		} else {
			console.log("Add Error")
		}
	}

	const handleClose = () => {
		props.setOpen(false);
	};

	const handleSave = () => {
		props.handleDialogEdit(edit);
		props.setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
			>

				{props.addMode === true ? ( 
				<Box component="form" noValidate onSubmit={(e) => {e.preventDefault(); handleAdd()}} sx={{ mt: 3 }}> 
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Add
					</DialogTitle>
					
					<DialogContent>
						{/* <DialogContentText>
							To subscribe to this website, please enter your email address here. We
							will send updates occasionally.
						</DialogContentText> */}
						<br/>
						{props.header && props.header.filter((x)=> x["field"] !== 'id' && x["field"] !== 'actions').map((col) => {
							const field = col.field
							return (
								<TextField
									required
									value={add[field] ? add[field] : ""}
									onChange={(e) => {
										setAdd({ ...add, [field]: 
											// field === "key_terms_en" || field === "subject_entity_en" || field === "key_terms_tc" || field === "subject_entity_tc" ? e.target.value.split(",") : 
											e.target.value? e.target.value:"" })
									}}
									autoFocus
									margin="dense"
									id={field}
									label={format(field, "name")}
									type="text"
									fullWidth
									variant="standard"
								/>
							)
						})}
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={handleClose}>
							Cancel
						</Button>
						<Button type="submit" autoFocus>
							Add
						</Button>
					</DialogActions>
				</Box> 
				):
				(
					<>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Edit
					</DialogTitle>
					
					<DialogContent>
						{/* <DialogContentText>
							To subscribe to this website, please enter your email address here. We
							will send updates occasionally.
						</DialogContentText> */}
						<br/>
						{edit && Object.entries(edit).map((field) => {
							return (
								<TextField
									value={field[1]}
									disabled={field[0] === "id" ? true : false}
									onChange={(e) => {
										setEdit({ ...edit, [field[0]]: e.target.value ? e.target.value:""
											// .split(",")
										 })
									}}
									autoFocus
									margin="dense"
									id={field[0]}
									label={format(field[0], "name")}
									type="text"
									fullWidth
									variant="standard"
								/>
							)
						})}
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={handleClose}>
							Cancel
						</Button>
						<Button onClick={handleSave}>Save</Button>
					</DialogActions>
					</>
				)
				}
			</Dialog>
		</div>
	);
}


//